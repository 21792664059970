<template>
  <div class="parkCarReport content">
    <div class="info" v-if="closeShow">
      <el-row>
        <el-col :span="24">
          <div class="info_content">
            <el-col :span="23">
              <span
                >停车卡报表，统计各渠道购买停车卡情况；适用于运营人员和财务人员了解停车卡销售情况。</span
              >
            </el-col>
            <el-col :span="1">
              <img src="./Report/img/close.png" @click="closeShow = false" alt="" />
            </el-col>
          </div>
        </el-col>
      </el-row>
    </div>
    <div class="searchWrapper">
      <div class="search_box_title">{{ $t('searchModule.Query_Table') }}</div>
      <el-form :inline="true" :model="formInline">
        <div class="col_box">
          <div class="col_left">
            <el-form-item :label="$t('searchModule.Date_search')">
              <a-date-picker
                ref="datePicker"
                :YearShowHidden="selectkeys[3]"
                :selectkeys="selectkeys"
                :previousDate="previousDate"
                :nextDate="nextDate"
              ></a-date-picker>
            </el-form-item>
          </div>
          <div class="col_right">
            <el-button
              type="primary"
              icon="el-icon-search"
              @click="
                page = 1;
                searchData();
              "
              >{{ $t('button.search') }}</el-button
            >
          </div>
        </div>
        <div class="col_box_boder" v-if="$route.meta.authority.button.export"></div>
        <div class="col_box h44">
          <div class="col_left"></div>
          <div class="col_right mbd4">
            <el-button type="" @click="exportFile" v-if="$route.meta.authority.button.export"
              ><i class="el-icon-upload2"></i>{{ $t('button.export') }}</el-button
            >
          </div>
        </div>
      </el-form>
    </div>
    <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
      <el-tab-pane label="按支付日期" name="first">
        <!--列表区域-->
        <div class="tableWrapper paddingB20 graphShadow">
          <!-- 筛选按钮 -->
          <div class="switch">
            <screen
              :checkBoxGroup="tableCols"
              :checkedColumns="checkedColumns"
              :reportType="3"
              @selectChange="selectChange"
            ></screen>
          </div>
          <el-table
            header-cell-class-name="headerCallStyle"
            :cell-style="callStyle"
            :data="tableData"
            style="width: 100%"
            min-height="400"
            :key="reload"
          >
            <template v-for="(item, index) in tableCols">
              <el-table-column
                v-if="tableCols[index] && tableCols[index].isShow"
                :label="item.label"
                :prop="item.prop"
                align="center"
                :min-width="item.width"
                :key="item.prop"
                :formatter="item.formatter"
              >
              </el-table-column>
            </template>
          </el-table>
          <!--分页器-->
          <div class="pagerWrapper" v-if="total > 0">
            <el-pagination
              v-if="total != 0"
              @current-change="handleCurrentChange"
              background
              :current-page="page"
              :page-size="pageSize"
              layout="total, prev, pager, next, jumper"
              :total="total"
            >
            </el-pagination>
          </div>
        </div>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import { exportExcelNew, dateFormat } from "@/common/js/public";
import screen from "@/components/screen/index";
export default {
  name: "parkCarReport",
  components: {
    screen,
  },
  data() {
    const start = new Date();
    const end = new Date(new Date().getTime() - 3600 * 1000 * 24);
    start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
    return {
      closeShow: true,
      selectkeys: ["date", "month", "week", "year"],
      previousDate: new Date(),
      nextDate: new Date(),
      tableDataTotal: [],
      searchDate: [dateFormat(start, "yyyy-MM-dd"), dateFormat(end, "yyyy-MM-dd")],
      activeName: "first",
      total: 0,
      page: 1,
      pageSize: 15,
      payType: [],
      formInline: {
        startDate: dateFormat(start, "yyyy-MM-dd"),
        endDate: dateFormat(end, "yyyy-MM-dd"),
        reportType: "",
      },
      tableData: [],
      tableData2: [],
      tableCols: [],
      tableCols2: [
        {
          label: "生效日期",
          prop: "plateNumber",
        },
        {
          label: "每日均摊总额",
          prop: "plateColor",
        },
      ],
      channelTypeList: [],
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > new Date().getTime() - 3600 * 1000 * 24;
        },
      },
      // 多选框的列表，列出表格的每一列
      checkBoxGroup: [],
      // 当前选中的多选框，代表当前展示的列
      checkedColumns: [],
      reload: false,
      screeningNum: 0,
    };
  },
  methods: {
    // table 单元格样式动态设置
    callStyle(row) {
      return {
        "border-right": 0,
      };
    },
    selectChange(selectList) {
      this.tableCols.filter((i, index) => {
        i.isShow = false;
      });
      selectList.forEach((i) => {
        this.tableCols[i].isShow = true;
      });
      this.searchData();
    },
    getChannelTypeList() {
      this.$axios.get("/acb/2.0/channelType/list").then((res) => {
        console.log(res, "res");
        this.channelTypeList = res.value;
        let arr1 = [
          {
            prop: "statDate",
            label: "支付日期",
            width: "",
          },
        ];
        let arr2 = [];
        this.channelTypeList.forEach((item) => {
          arr2.push({
            prop: item.code + "",
            label: item.desc,
            width: [17, 21].includes(item.code) ? "140" : "",
            formatter: (row, column, cellValue, index) => {
              return cellValue ? (cellValue / 100).toFixed(2) + "元" : `0.00元`;
            },
          });
        });
        let arr3 = [
          {
            prop: "total",
            label: "合计",
            width: "",
            formatter: (row, column, cellValue, index) => {
              return cellValue ? (cellValue / 100).toFixed(2) + "元" : `0.00元`;
            },
          },
        ];
        this.tableCols = arr1
          .concat(arr2)
          .concat(arr3)
          .map((item) => {
            return {
              ...item,
              isShow: false,
            };
          });
        this.getReportFieldHide();
        this.searchData();
      });
    },
    getReportFieldHide() {
      this.$axios
        .get("/acb/2.0/reportFieldHide/get", {
          data: {
            reportType: 3,
          },
        })
        .then((res) => {
          if (res.value.length == 1 && res.value[0] == "") {
            this.checkedColumns = [];
          } else {
            this.checkedColumns = res.value.map(Number);
          }
          this.checkedColumns.forEach((i) => {
            this.tableCols[i].isShow = true
          });
        });
    },
    // 分页器跳转指定页
    handleCurrentChange(val) {
      this.page = val;
      this.computedPage();
    },
    // 查询车牌号
    querySearchAsync(queryString, cb) {
      this.formInline.carId = "";
      queryString = queryString.replace(/\s/g, "");
      if (queryString.length < 3) {
        cb([]);
        return;
      }
      this.$axios
        .get("/acb/2.0/parkCardStat/statementByPayType", {
          data: {
            part: queryString,
            size: 100,
          },
        })
        .then((res) => {
          if (res.state == 0) {
            cb(res.value);
          }
        });
    },
    exportFile() {
      // this.formInline.startDate = this.searchDate[0];
      // this.formInline.endDate = this.searchDate[1];
      this.formInline.startDate = this.$refs.datePicker ? this.$refs.datePicker.getStartDate() : "";
      this.formInline.endDate = this.$refs.datePicker ? this.$refs.datePicker.getEndDate() : "";
      // this.formInline.page = this.page;
      // this.formInline.pageSize = this.pageSize;
      exportExcelNew("/acb/2.0/parkCardStat/statementByPayType/export", this.formInline);
    },
    searchData() {
      // this.formInline.startDate = this.searchDate[0];
      // this.formInline.endDate = this.searchDate[1];
      this.formInline.startDate = this.$refs.datePicker ? this.$refs.datePicker.getStartDate() : "";
      this.formInline.endDate = this.$refs.datePicker ? this.$refs.datePicker.getEndDate() : "";
      this.formInline.reportType = this.$refs.datePicker ? this.$refs.datePicker.getDateType() : "";
      this.$axios
        .get("/acb/2.0/parkCardStat/statementByPayType", {
          data: this.formInline,
        })
        .then((res) => {
          this.tableDataTotal = [];
          res.value.forEach((item) => {
            console.log(JSON.parse(item));
            this.tableDataTotal.push(JSON.parse(item));
            // item = JSON.parse(item);
          });
          // this.tableDataTotal = res.value;
          // console.log(res.value);
          this.computedPage();
          this.total = this.tableDataTotal.length * 1 || 0;
        })
        .catch((err) => {
          this.tableData = [];
          this.total = 0;
        });
    },
    computedPage() {
      let pageNum = this.page;
      let pageSize = this.pageSize;
      let tableData = [];
      let len =
        pageNum * pageSize >= this.tableDataTotal.length
          ? this.tableDataTotal.length
          : pageNum * pageSize;
      let currentNum = pageNum > 1 ? pageSize * (pageNum - 1) : 0;
      for (let i = currentNum; i < len; i++) {
        tableData.push(this.tableDataTotal[i]);
      }
      this.tableData = tableData;
    },
    handleClick(value, value2) {},
  },
  created() {
    this.getChannelTypeList();
  },
};
</script>

<style scoped lang="stylus" rel="stylesheet/stylus">
.parkCarReport
  background: #ffffff;
.breadcrumb
  height 35px
.demo-form-inline
  overflow hidden
  padding 22px 0 0 0
  margin-bottom 20px
  .but
    float: right;
.table_header
  padding 30px 60px
  border 1px solid #ddd
.grid-content
  text-align center
  line-height 30px
.countTable
  padding-top 60px
  height 350px
.pagerWrapper
  text-align right
  margin-top 28px
  font-size 12px
>>>.el-tabs__nav
  background-color #fff
>>>.el-tabs__header
  margin 0
  padding 0 20px
  border-bottom: none
.info
  margin: 0 -20px 0 -20px;
  padding-left: 20px;
  height: 40px;
  line-height: 40px;
  background: rgba(254, 242, 213, 0.47);
  .info_content
    background: rgba(254, 242, 213, 1);
    color: rgba(255, 151, 74, 1);
  img
    width: 24px;
    height: 24px;
    vertical-align: middle;
    cursor: pointer;
.tableWrapper {
  position: relative;
  .switch {
    position: absolute;
    right: 0px;
    z-index: 2;
    top: 1px;
    cursor: pointer;

    img {
      height: 54px;
      width: 40px;
    }
  }
}
.tableWrapper >>> .headerCallStyle {
  height: 54px;
  font-size: 14px;
  background: #F3F7FF!important;
  padding: 5px;
  border-right: 0
  font-weight: 600
  color: rgba(0, 0, 0, 0.85)
}

.tableWrapper >>> .el-table {
  width: 100%!important;
}
</style>
